.countdown-wrapper {
  font-family: 'AMXRegular', Times, serif;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.countdown-item {
  font-family: 'AMXBold', Times, serif;
  color: #fff;
  font-size: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  line-height: 30px;
  margin: 20px;
  padding-top: 10px;
  position: relative;
  width: 216;
  height: 216px;
  font-weight: 900;
}

.countdown-item span {
  font-family: 'AMXRegular', Times, serif;
  font-size: 42px;
  font-weight: 300;
  margin-top: 40px;
}

.countdown-svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 216px;
  height: 216px;
}

@media (max-width: 1700px) {
  .countdown-item {
    font-size: 80px;
    width: 150px;
    height: 150px;
    margin: 5px;
  }
  .countdown-item span {
    margin-top: 30px;
  }

  .countdown-svg {
    width: 150px;
    height: 150px;
  }
  .countdown-item span {
    font-size: 25px;
  }
}

@media (max-width: 768px) {
  .countdown-item {
    font-size: 60px;
    width: 120px;
    height: 120px;
    margin: 5px;
  }
  .countdown-item span {
    margin-top: 0px;
  }

  .countdown-svg {
    width: 120px;
    height: 120px;
  }
  .countdown-item span {
    font-size: 20px;
  }
}

@media (max-width: 480px) {
  .countdown-item {
    font-size: 40px;
    width: 100px;
    height: 100px;
    margin: 5px;
  }

  .countdown-svg {
    width: 100px;
    height: 100px;
  }
  .countdown-item span {
    font-size: 14px;
    margin-top: 0px;
  }
}
